var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "5", 
            "6", 
            "7", 
            "8", 
            "9", 
            "10", 
            "11", 
            "12", 
            "13", 
            "14", 
            "15", 
            "16", 
            "17", 
            "18", 
            "19", 
            "20", 
            "21", 
            "22", 
            "23", 
            "24", 
            "25", 
            "26", 
            "27", 
            "28", 
            "29", 
            "30", 
            "31", 
            "32"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "adı", 
                    "translation": {
                        "en": "name", 
                        "tr": "adı"
                    }, 
                    "value": "1"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "10": {
                "name": {
                    "label": "banka ünvan 1", 
                    "translation": {
                        "en": "bank title 1", 
                        "tr": "banka ünvan 1"
                    }, 
                    "value": "10"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "11": {
                "name": {
                    "label": "iban 1", 
                    "translation": {
                        "en": "iban 1", 
                        "tr": "iban 1"
                    }, 
                    "value": "11"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "12": {
                "name": {
                    "label": "banka açıklama", 
                    "translation": {
                        "en": "bank info", 
                        "tr": "banka açıklama"
                    }, 
                    "value": "12"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "13": {
                "name": {
                    "label": "banka adı 2", 
                    "translation": {
                        "en": "bank name 2", 
                        "tr": "banka adı 2"
                    }, 
                    "value": "13"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "14": {
                "name": {
                    "label": "banka ünvan 2", 
                    "translation": {
                        "en": "bank title 2", 
                        "tr": "banka ünvan 2"
                    }, 
                    "value": "14"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "15": {
                "name": {
                    "label": "iban 2", 
                    "translation": {
                        "en": "iban 2", 
                        "tr": "iban 2"
                    }, 
                    "value": "15"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "16": {
                "name": {
                    "label": "banka açıklama 2", 
                    "translation": {
                        "en": "bank info 2", 
                        "tr": "banka açıklama 2"
                    }, 
                    "value": "16"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "17": {
                "name": {
                    "label": "banka adı 3", 
                    "translation": {
                        "en": "bank name 3", 
                        "tr": "banka adı 3"
                    }, 
                    "value": "17"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "18": {
                "name": {
                    "label": "banka ünvan 3", 
                    "translation": {
                        "en": "bank title 3", 
                        "tr": "banka ünvan 3"
                    }, 
                    "value": "18"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "19": {
                "name": {
                    "label": "iban 3", 
                    "translation": {
                        "en": "iban 3", 
                        "tr": "iban 3"
                    }, 
                    "value": "19"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "2": {
                "name": {
                    "label": "logo", 
                    "translation": {
                        "en": "shop logo", 
                        "tr": "logo"
                    }, 
                    "value": "2"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "image"
            }, 
            "20": {
                "name": {
                    "label": "banka açıklama 3", 
                    "translation": {
                        "en": "bank info 3", 
                        "tr": "banka açıklama 3"
                    }, 
                    "value": "20"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "21": {
                "name": {
                    "label": "banka adı 4", 
                    "translation": {
                        "en": "bank name 4", 
                        "tr": "banka adı 4"
                    }, 
                    "value": "21"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "22": {
                "name": {
                    "label": "banka ünvan 4", 
                    "translation": {
                        "en": "bank title 4", 
                        "tr": "banka ünvan 4"
                    }, 
                    "value": "22"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "23": {
                "name": {
                    "label": "iban 4", 
                    "translation": {
                        "en": "iban 4", 
                        "tr": "iban 4"
                    }, 
                    "value": "23"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "24": {
                "name": {
                    "label": "banka açıklama 4", 
                    "translation": {
                        "en": "bank info 4", 
                        "tr": "banka açıklama 4"
                    }, 
                    "value": "24"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "25": {
                "name": {
                    "label": "banka adı 5", 
                    "translation": {
                        "en": "bank name 5", 
                        "tr": "banka adı 5"
                    }, 
                    "value": "25"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "26": {
                "name": {
                    "label": "banka ünvan 5", 
                    "translation": {
                        "en": "bank title 5", 
                        "tr": "banka ünvan 5"
                    }, 
                    "value": "26"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "27": {
                "name": {
                    "label": "iban 5", 
                    "translation": {
                        "en": "iban 5", 
                        "tr": "iban 5"
                    }, 
                    "value": "27"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "28": {
                "name": {
                    "label": "banka açıklama 5", 
                    "translation": {
                        "en": "bank info 5", 
                        "tr": "banka açıklama 5"
                    }, 
                    "value": "28"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "29": {
                "name": {
                    "label": "mail", 
                    "translation": {
                        "en": "mail", 
                        "tr": "mail"
                    }, 
                    "value": "29"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "3": {
                "name": {
                    "label": "adres", 
                    "translation": {
                        "en": "shop address", 
                        "tr": "adres"
                    }, 
                    "value": "3"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "30": {
                "name": {
                    "label": "telefon", 
                    "translation": {
                        "en": "phone", 
                        "tr": "telefon"
                    }, 
                    "value": "30"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "31": {
                "name": {
                    "label": "kısa ad", 
                    "translation": {
                        "en": "short name", 
                        "tr": "kısa ad"
                    }, 
                    "value": "31"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "32": {
                "name": {
                    "label": "ticaret sicil no", 
                    "translation": {
                        "en": "trade register number", 
                        "tr": "ticaret sicil no"
                    }, 
                    "value": "32"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "4": {
                "name": {
                    "label": "vergi dairesi", 
                    "translation": {
                        "en": "shop tax office", 
                        "tr": "vergi dairesi"
                    }, 
                    "value": "4"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "5": {
                "name": {
                    "label": "vergi numarası", 
                    "translation": {
                        "en": "shop tax number", 
                        "tr": "vergi numarası"
                    }, 
                    "value": "5"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "6": {
                "name": {
                    "label": "mersis no", 
                    "translation": {
                        "en": "mersis no", 
                        "tr": "mersis no"
                    }, 
                    "value": "6"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "7": {
                "name": {
                    "label": "vergi oranı", 
                    "translation": {
                        "en": "tax rate percent", 
                        "tr": "vergi oranı"
                    }, 
                    "value": "7"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "float"
            }, 
            "8": {
                "name": {
                    "label": "website", 
                    "translation": {
                        "en": "shop web link", 
                        "tr": "website"
                    }, 
                    "value": "8"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "9": {
                "name": {
                    "label": "banka adı 1", 
                    "translation": {
                        "en": "bank name 1", 
                        "tr": "banka adı 1"
                    }, 
                    "value": "9"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "user shop", 
            "tr": "kullanıcı mağaza"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
